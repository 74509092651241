/* CookieBar.css */

.cookie-bar {
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .cookie-bar__text {
    margin: 0;
  }
  
  .cookie-bar__button {
    background-color: #fff;
    color: #333;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }